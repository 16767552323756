import React from "react";
import NavBarHomePage from "../components/navigations/NavBarHomePage";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Link from "gatsby-link";
import video_url from '../../static/videos/sepro.mp4';
const IndexPage = () => {

    const playStopVideo = (e) => {
        // Get the video
        const video = document.getElementById("background-video");
        //const seproText = document.getElementById("sepro");
        // Get the button
        //const btn = document.getElementById("playBtn");
        // Pause and play the video, and change the button text
        if (video.paused) {
          //  seproText.style.display = "none";
            video.play();
            //btn.innerHTML = "Pause";
        } else {
            video.pause();
          //  seproText.style.display = "block";
            //btn.innerHTML = "Play";
        }
    }
    const { t } = useTranslation();
    
    return (

        <header className="relative flex flex-col justify-between h-screen overflow-hidden bg-home">
            <NavBarHomePage/>
            <div className="z-30 max-w-8xl sm:px-6 lg:px-8">
                <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center">
                    <div className="mt-12 lg:mt-12 lg:col-span-6 xl:col-span-8 mb-2">
                        <div id="sepro" className="relative flex flex-col max-w-2xl px-4  text-left sm:py-6 lg:mx-2">
                            <p className="mt-2 text-sm md:text-base text-gray-200">{t("home_msg_7")}</p>
                             <ul className="pl-10 mt-1 text-sm md:text-base text-cyan-900 list-disc ">
                                <li className="mt-1 list-item"><span className="text-gray-200">{t("home_msg_5")}</span></li>
                                <li className="mt-1 list-item"><span className="text-gray-200">{t("home_msg_6")}</span></li>
                            </ul>
                            <Link to={'/about'}
                                  className="inline-flex items-center justify-center max-w-fit inline px-8 py-3 mt-8 text-base font-medium bg-cyan-900 border border-transparent text-slate-100 hover:bg-teal-600"
                            >
                                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.75 1L5.32 5.57L0.75 10.14" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span className="ml-2">{t("read_more")}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="flex-auto lg:col-span-5 xl:col-span-4 text-center align-top animate-pulse">
                        <button id="playBtn" type="button" onClick={() => playStopVideo()} >
                            <img
                                src={"play.png"}
                                alt="Play"
                                className="object-cover object-center w-full h-full"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <video className=" absolute object-cover object-center w-full" id="background-video"
                   autoPlay={true}
                   loop
                   poster={'background-home.jpg'}
            >
                <source
                    src={video_url}
                    type="video/mp4"
                />
                <source
                    src={video_url}
                    type="video/ogg"
                />
                Your browser does not support the video tag.
            </video>
        </header>

    );
};
export default IndexPage;